import React, { memo } from "react";
import { useRegions } from "~/hooks";

import { Heading } from "~/components/Heading";
import { Select } from "~/components/Select";

const Options_ = () => {
  const {
    currentRegionValue,
    loading,
    regionOptions,
    handleManualRegionChange,
  } = useRegions();
  return (
    <>
      <Heading level={3}>Optionen</Heading>
      {currentRegionValue && !loading && (
        <Select
          options={regionOptions!}
          label="Region"
          onChange={handleManualRegionChange}
          value={currentRegionValue}
        ></Select>
      )}
    </>
  );
};

export const Options = memo(Options_);
