import { memo, useCallback, useEffect } from "react";
import { Drawer } from "~/components/Drawer";
import { NodePreview } from "~/components/NodePreview";
import { usePreviewRouteParam } from "~/hooks";
import { $ui } from "~/stores";
import { ToggleableSlideup } from "../ToggleableSlideup";

const PreviewSlideUp_ = () => {
  const [previewRoute, setPreviewRoute] = usePreviewRouteParam();
  const previewIsOpen = !!previewRoute;

  const handleClose = useCallback(() => {
    setPreviewRoute(null);
  }, [setPreviewRoute]);

  useEffect(() => {
    $ui.setKey("contentHasOverlay", previewIsOpen);
    $ui.setKey("contentIsScrollable", !previewIsOpen);
  }, [previewIsOpen]);

  const previewContent = previewRoute ? (
    <ToggleableSlideup
      actionType="close"
      onToggle={handleClose}
      label="Vorschau schließen"
    >
      <NodePreview route={previewRoute} />
    </ToggleableSlideup>
  ) : null;

  return (
    <Drawer
      label="Inhaltsvorschau"
      isOpen={previewIsOpen}
      drawerSize={160}
      side="bottom"
      zIndex={30}
      onClosed={handleClose}
    >
      {previewContent}
    </Drawer>
  );
};

export const PreviewSlideUp = memo(PreviewSlideUp_);
