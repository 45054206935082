import { css } from "@emotion/css";

type DrawerContentProps = {
  padding?: boolean;
  children: React.ReactNode;
};

export const DrawerContent = ({
  children,
  padding = true,
}: DrawerContentProps) => (
  <div
    className={css`
      padding: ${padding ? "var(--margin-lg)" : "0"};
    `}
  >
    {children}
  </div>
);
