import React, { memo, useCallback } from "react";
import { FormElement } from "~/components/FormElement";
import cx from "classnames";
import * as styles from "./CheckRadio.module.css";

type CheckRadioProps = {
  type: "radio" | "checkbox";
  onChange?: (val: boolean) => void;
  value?: string;
  checked?: boolean;
  label?: string;
  className?: string;
  loading?: boolean;
};

const CheckRadio_ = ({
  type,
  onChange,
  value,
  checked,
  label,
  className = "",
  loading,
}: CheckRadioProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange && onChange(e.target.checked),
    [onChange]
  );
  return (
    <FormElement
      className={className}
      label={label}
      flow="horizontal"
      labelAfter={true}
      labelStyle="normal"
      loading={loading}
    >
      <div className={styles.inputWrapper}>
        {loading ? (
          <span className={styles.input} />
        ) : (
          <input
            className={styles.input}
            type={type}
            onChange={handleChange}
            value={value}
            checked={checked}
          />
        )}
        <span
          className={cx({
            [styles.inputSuffix]: true,
            [styles.inputSuffixCheckbox]: type === "checkbox",
            [styles.inputSuffixRadio]: type === "radio",
          })}
        />
      </div>
    </FormElement>
  );
};

export const CheckRadio = memo(CheckRadio_);
