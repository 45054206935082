import cx from "classnames";
import { memo } from "react";
import { Link, useMatch } from "react-router-dom";
import { closeDrawer } from "~/stores";
import * as styles from "./MainMenu.module.css";

type MenuItemDef = [string, string];

const menuItems: MenuItemDef[] = [
  ["Startseite", "/"],
  ["Informieren", "/informieren"],
  ["Erkunden", "/erkunden"],
  ["Rundgänge", "/rundgaenge"],
  ["Biografien", "/biografien"],
  ["Glossar", "/glossar"],
  ["Impressum", "/seite/impressum"],
  ["Datenschutzerklärung", "/seite/datenschutzerklaerung"],
];

type MainMenuLinkProps = {
  path: string;
  label: string;
  onClick: () => void;
};
const MainMenuLink = memo(({ path, label, onClick }: MainMenuLinkProps) => {
  const match = useMatch(path);
  const classes = cx({
    [styles.a]: true,
    [styles.aActive]: match,
  });
  return (
    <Link to={path} onClick={onClick} className={classes}>
      {label}
    </Link>
  );
});
MainMenuLink.displayName = "MainMenuLink";

const MainMenu_ = () => {
  return (
    <nav className={styles.mainMenu}>
      <ul className={styles.ul}>
        {menuItems.map(([title, path], i) => (
          <li className={styles.li} key={i}>
            <MainMenuLink label={title} path={path} onClick={closeDrawer} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export const MainMenu = memo(MainMenu_);
