import React, { memo, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { RouteDefinition } from "~/routes/routeConfig";

import { Breadcrumb, BreadcrumbItemPropInterface } from "./Breadcrumb";

const beginsWith = (haystack: string, needle: string) =>
  haystack.substring(0, needle.length) === needle;

const trimSlashes = (str: string) => str.replace(/^\//, "").replace(/\/$/, "");

const BreadcrumbRouteMatch = ({
  routeConfig,
  path,
}: {
  routeConfig: RouteDefinition[];
  path: string;
}) => {
  const breadcrumbSectionsRouteConfig = useMemo(
    () =>
      routeConfig.reduce((acc, route) => {
        const sectionIsActive = beginsWith(
          trimSlashes(path),
          trimSlashes(route.path)
        );
        // This route is not a substring of the current route and therefore
        // not active in the context of the breadcrumb
        if (!sectionIsActive) {
          return acc;
        }

        return [
          ...acc,
          {
            path: route.path.replace(/^\/?/, "/"),
            name: route.name,
            Element: route.elementBreadcrumb,
          },
        ];
      }, [] as { path: string; name: string; Element?: (props: BreadcrumbItemPropInterface) => React.ReactNode }[]),
    [path, routeConfig]
  );
  return <Breadcrumb entries={breadcrumbSectionsRouteConfig} />;
};

const BreadcrumbContainer_ = ({ routes }: { routes: RouteDefinition[] }) => {
  return (
    <Routes>
      {routes.map(({ path }, i) => (
        <Route
          key={i}
          path={path}
          element={<BreadcrumbRouteMatch routeConfig={routes} path={path} />}
        />
      ))}
    </Routes>
  );
};

export const BreadcrumbContainer = memo(BreadcrumbContainer_);
