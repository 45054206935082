import { useEffect } from "react";
import { useQueryParam } from "./useQueryParam";

export const usePreviewRouteParam = (
  def?: string | null
): [string | null, (r: string | null) => void] => {
  const [previewRoute, setPreviewRoute] = useQueryParam<string | null>(
    "preview"
  );

  useEffect(() => {
    if (typeof def !== "undefined" && typeof previewRoute === "undefined") {
      setPreviewRoute(def, {
        replace: false,
      });
    }
  }, [setPreviewRoute, def, previewRoute]);

  return [previewRoute, setPreviewRoute] as [
    string | null,
    (route: string | null) => void
  ];
};
