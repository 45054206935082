import { memo, useMemo } from "react";

import * as styles from "./Logo.module.css";

const Logo_ = ({ variant = "dark" }: { variant: "dark" | "light" }) => {
  const color = useMemo(
    () =>
      variant === "dark" ? "var(--logo-color-dark)" : "var(--logo-color-light)",
    [variant]
  );
  return (
    <svg viewBox="0 0 325.9843 283.4646" className={styles.logo}>
      <title>SiRo</title>
      <g>
        <path
          fill={color}
          d="M38.6873,175.7067c-8.3854,0-17.7837-4.3386-25.0317-10.6472L0.4252,180.8649 c10.3934,9.715,24.4861,14.7355,37.7462,14.7355c24.2821,0,38.3926-14.6632,38.3926-31.5858 c0-14.4034-7.7698-22.6892-20.2151-27.7216l-12.9837-5.259c-8.8942-3.5404-15.561-5.8105-15.561-12.061 c0-5.8295,5.0562-9.1386,13.0003-9.1386c8.0557,0,14.4367,2.8323,21.1497,7.9821l11.6305-14.5185 c-8.7768-8.7306-21.0465-13.355-32.7802-13.355c-21.2114,0-36.2956,13.4842-36.2956,30.3725 c0,14.8055,10.0044,23.4815,20.5638,27.7762l13.2411,5.6468c8.9037,3.659,14.6952,5.718,14.6952,12.1345 C53.0089,171.9209,48.305,175.7067,38.6873,175.7067"
        />
        <rect
          x="92.0803"
          y="116.154"
          fill={color}
          width="22.9834"
          height="77.5692"
        />
        <path
          fill={color}
          d="M185.1596,110.1107h11.7846c12.1179,0,18.6282,3.3648,18.6282,13.4404 c0,10.0222-6.5102,15.2088-18.6282,15.2088h-11.7846V110.1107z M238.1514,123.551c0-24.2275-17.7173-31.7305-39.3308-31.7305 h-36.7001v101.9022h23.0391V157.05h12.6671l19.4572,36.6728h25.7801l-23.0676-40.7718 C230.8808,148.0336,238.1514,138.5345,238.1514,123.551"
        />
        <path
          fill={color}
          d="M287.7737,177.0512c-9.5074,0-14.2303-8.6606-14.2303-22.0855c0-13.4238,4.7229-22.1401,14.2303-22.1401 c9.5062,0,14.2303,8.7163,14.2303,22.1401C302.004,168.3907,297.28,177.0512,287.7737,177.0512 M287.7737,114.2769 c-19.628,0-37.7853,14.9004-37.7853,40.6888c0,25.7338,18.1573,40.6342,37.7853,40.6342s37.7853-14.9004,37.7853-40.6342 C325.5591,129.1773,307.4018,114.2769,287.7737,114.2769"
        />
        <path
          fill={color}
          d="M115.0612,98.8491H92.1115V46.4733C92.1115,20.8475,112.959,0,138.5848,0 c25.6264,0,46.4745,20.8475,46.4745,46.4733v28.0425h-22.9497V46.4733c0-12.9707-10.5535-23.5236-23.5248-23.5236 c-12.9707,0-23.5236,10.5529-23.5236,23.5236V98.8491z"
        />
        <path
          fill={color}
          d="M68.5421,283.4636c-25.6507,0-46.5185-20.8683-46.5185-46.5191v-25.9171h22.9497v25.9171 c0,12.9957,10.5731,23.5694,23.5688,23.5694c12.9962,0,23.5694-10.5737,23.5694-23.5694v-25.9171h22.9497v25.9171 C115.0612,262.5953,94.1929,283.4636,68.5421,283.4636z"
        />
      </g>
    </svg>
  );
};

export const Logo = memo(Logo_);
