import cx from "classnames";
import * as styles from "./Button.module.css";
import { Link } from "react-router-dom";
import { memo } from "react";

type ButtonProps = {
  href?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  iconPosition?: "before" | "after";
  level?: 1 | 2 | 3;
  size?: "sm" | "md" | "lg";
  alignment?: "center" | "left" | "right";
  textAlignment?: "center" | "left" | "right";
  fullWidth?: boolean;
  label?: string;
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
  breakText?: boolean;
};

const Button_ = ({
  href,
  children,
  icon,
  iconPosition = "before",
  size = "md",
  level = 2,
  alignment = "left",
  textAlignment = "center",
  fullWidth = false,
  breakText = false,
  label,
  className = "",
  iconClassName = "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
}: ButtonProps) => {
  const Element = !!href ? Link : "button";

  return (
    <Element
      title={label}
      to={href as string}
      className={cx({
        [styles.button]: true,
        [styles.buttonSizeSm]: size === "sm",
        [styles.buttonSizeMd]: size === "md",
        [styles.buttonSizeLg]: size === "lg",
        [styles.buttonLevelOne]: level === 1,
        [styles.buttonLevelTwo]: level === 2,
        [styles.buttonLevelThree]: level === 3,
        [styles.buttonLeft]: alignment === "left",
        [styles.buttonCenter]: alignment === "center",
        [styles.buttonRight]: alignment === "right",
        [styles.buttonTextLeft]: textAlignment === "left",
        [styles.buttonTextCenter]: textAlignment === "center",
        [styles.buttonTextRight]: textAlignment === "right",
        [styles.buttonFullWidth]: fullWidth,
        [styles.buttonBreakText]: breakText,
        [className as string]: true,
      })}
      onClick={onClick}
    >
      {icon && iconPosition === "before" && (
        <span
          className={cx({
            [styles.buttonIcon]: true,
            [styles.buttonIconBefore]: iconPosition === "before",
            [iconClassName]: !!iconClassName,
          })}
        >
          {icon}
        </span>
      )}
      <span className={styles.buttonText}>{children}</span>
      {icon && iconPosition === "after" && (
        <span
          className={cx({
            [styles.buttonIcon]: true,
            [styles.buttonIconAfter]: iconPosition === "after",
            [iconClassName]: !!iconClassName,
          })}
        >
          {icon}
        </span>
      )}
    </Element>
  );
};

export const Button = memo(Button_);
