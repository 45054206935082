import { NodeType } from "./nodeTypes";

export const strings: { [x: string]: string } = {
  Home: "Startseite",
  Settings: "Einstellungen",
  "About the app": "Über die App",
  Search: "Suche",
  Menu: "Menü",
  "Page not found!": "Inhalt nicht gefunden!",
  "The requested page could not be found.":
    "Die angeforderte Seite konnte nicht gefunden werden!",
  Share: "Inhalt Teilen",
  Back: "Zurück",
  Forward: "Vorwärts",
  Favorite: "Zu den Favoriten hinzufügen",
  Filter: "Ansicht filtern",
  Backgrounds: "Hintergründe",
  Biographies: "Biografien",
  Places: "Orte",
  Pages: "Seiten",
  Tours: "Themenrundgänge",
  "Navigation history": "Navigationsverlauf",
};

// These labels are used for the region context display
export const nodeTypeListingLabels = {
  [NodeType.GlossaryEntry]: "Glossar-Einträge",
  [NodeType.Place]: "Orte",
  [NodeType.Biography]: "Biografien",
  [NodeType.BackgroundInfo]: "Hintergrund-Artikel",
  [NodeType.BasicPage]: "Seiten",
  [NodeType.TopicTour]: "Rundgänge",
};

export const nodeTypeSingleLabels = {
  [NodeType.GlossaryEntry]: "Glossar-Eintrag",
  [NodeType.Place]: "Ort",
  [NodeType.Biography]: "Biografie",
  [NodeType.BackgroundInfo]: "Hintergrund-Artikel",
  [NodeType.BasicPage]: "Seite",
  [NodeType.TopicTour]: "Rundgang",
};

type NodeListingOptions = {
  heading: string;
  summary: string;
};
// these are the texts used on the node listing heading sections.
export const nodeListingHeadings: { [x in NodeType]: NodeListingOptions } = {
  BackgroundInfo: {
    heading: "Informieren",
    summary:
      "Schon gewusst, dass Sinti und Roma aufgrund ihrer langen Geschichte in Deutschland zu den vier nationalen Minderheiten gehören? Informiere dich über verschiedene Hintergrundthemen zur Geschichte von Sinti und Roma und Antiziganismus in Hessen.",
  },
  BasicPage: {
    heading: "Seiten",
    summary: "",
  },
  Biography: {
    heading: "Biografien",
    summary:
      "Schon gewusst, dass in den meisten Städten und Gemeinden Sinti oder Roma wohnen und gewohnt haben? Sinti oder Roma waren und sind Nachbar*innen, Kolleg*innen und Mitschüler*innen. Lerne einige von ihnen mit ihren Geschichten kennen.",
  },
  Place: {
    heading: "Erkunden",
    summary:
      "Neugierig etwas über Sinti und Roma in deiner Region zu erfahren? Erkunde verschiedene Orte und erfahre etwas über Geschichte und Gegenwart von Sinti und Roma. Orte können als Standorte auf einer Karte angezeigt werden oder in einer Listenansicht ausgewählt werden.",
  },
  GlossaryEntry: {
    heading: "Glossar",
    summary:
      "Schon gewusst, dass Hessen als zweiter Staat im Deutschen Reich ein eigenes Gesetz zur Verfolgung von Sinti und Roma hatte? Oder, dass Sinti und Roma ab 1939 ihren Wohnsitz nichtmehr verlassen durften? Im Glossar werden viele wichtige Begriffe und historische Ereignisse erklärt.",
  },
  TopicTour: {
    heading: "Rundgänge",
    summary:
      "Sehe deine Stadt mit anderen Augen und wähle einen der Rundgänge aus. Wähle ein Thema oder folge den Spuren von einzelnen Biographien und erfahre so etwas über die Geschichte von Sinti und Roma in Hessen und deiner Stadt.",
  },
};
