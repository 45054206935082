import { memo, useEffect } from "react";

import { Drawer, DrawerContent } from "~/components/Drawer";
import { Filters } from "~/components/Filters";
import { MainMenu } from "~/components/MainMenu";
import { Options } from "~/components/Options";
import { $ui, useUiState } from "~/stores";

type SidebarProps = {
  blurRef: React.RefObject<HTMLElement>;
};

const Sidebar_ = ({ blurRef }: SidebarProps) => {
  const { drawerState } = useUiState();
  const isOpen = drawerState !== "Closed";
  useEffect(() => {
    $ui.setKey("contentHasOverlay", isOpen);
    $ui.setKey("contentIsScrollable", !isOpen);
  }, [isOpen]);
  return (
    <Drawer
      label="Seitenleiste"
      isOpen={isOpen}
      unfocusElRef={blurRef}
      zIndex={50}
    >
      {drawerState === "Filters" && (
        <DrawerContent>
          <Filters />
        </DrawerContent>
      )}
      {drawerState === "Menu" && (
        <DrawerContent padding={false}>
          <MainMenu />
        </DrawerContent>
      )}
      {drawerState === "Options" && (
        <DrawerContent>
          <Options />
        </DrawerContent>
      )}
    </Drawer>
  );
};

export const Sidebar = memo(Sidebar_);
