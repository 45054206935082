import { css } from "@emotion/css";
import cx from "classnames";
import { Interweave } from "interweave";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  BookOpen,
  Crosshair,
  Lock,
  MoreVertical,
  Settings,
} from "react-feather";
import { Link } from "react-router-dom";
import { Skeleton } from "~/components/Skeleton";
import { NodeType, NodeTypeScopeRoutes } from "~/constants";
import { usePreviewRouteParam, useVerticalLineClamp } from "~/hooks";

import { strikethroughUtf8ToHtml } from "~/utils";
import * as styles from "./Copy.module.css";

export interface CopyProps {
  children?: string;
  className?: string;
  loading?: boolean;
  loadingLines?: number;
  maxHeight?: number;
  isInline?: boolean;
  element?: keyof JSX.IntrinsicElements;
}

const isGlossaryEntry = (href: string) =>
  href
    .replace(/^\//, "")
    .substring(0, NodeTypeScopeRoutes[NodeType.GlossaryEntry].length) ===
  NodeTypeScopeRoutes[NodeType.GlossaryEntry];

const replacementMap: Record<
  string,
  FunctionComponent<{ el: HTMLElement; children: React.ReactNode }>
> = {
  // Logos:
  "LOGO VDSRLVH": () => (
    <img
      src="/sinti-roma-hessen-logo.svg"
      alt="Logo des »Verband Deutscher  Sinti und  Roma – Landesverband Hessen«"
      className={css`
        max-width: 100%;
        width: 10em;
        height: auto;
      `}
    />
  ),
  "LOGO HMSI": () => (
    <img
      src="/hmsi_logoweb.svg"
      alt="Logo des Hessischen Ministerium für Soziales und Integration"
      className={css`
        max-width: 100%;
        width: 10em;
        height: auto;
      `}
    />
  ),

  // Interactive elements & widgets:
  "MATOMO OPT-OUT": () => (
    <iframe
      className={css`
        border: 0;
        height: 200px;
        width: 100%;
      `}
      src="https://analytics.ifg.io/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=dfe8ff&fontColor=0b30bc&fontSize=&fontFamily='source%20sans%20pro'%2Csans-serif"
    ></iframe>
  ),

  // Icons:
  "ICON CROSSHAIR": () => <Crosshair width={16} height={16}></Crosshair>,
  "ICON MORE_VERTICAL": () => (
    <MoreVertical width={16} height={16}></MoreVertical>
  ),
  "ICON SETTINGS": () => <Settings width={16} height={16}></Settings>,
  "ICON LOCK": () => <Lock width={16} height={16}></Lock>,
};

const transformFactory =
  (handlePreviewRoute: (e: React.MouseEvent<HTMLAnchorElement>) => void) =>
  // eslint-disable-next-line react/display-name
  (el: HTMLElement, children: React.ReactNode): React.ReactNode => {
    const href = el.getAttribute("href");
    const tagName = el.tagName;

    if (Object.keys(replacementMap).includes(el.innerText)) {
      return replacementMap[el.innerText]({ el, children });
    }

    if (tagName === "A" && href) {
      const openInPreviewModal = isGlossaryEntry(href);
      // Replace the glossary links:
      if (openInPreviewModal) {
        return (
          <Link to={href} onClick={handlePreviewRoute}>
            <BookOpen
              strokeWidth={2}
              width={12}
              height={12}
              className={css`
                display: inline-block;
                vertical-align: middle;
              `}
            />{" "}
            {children}
          </Link>
        );
      } else if (href.substring(0, 1) === "/") {
        // otherwise normalize the URL for the app:
        return <Link to={href.replace(/^\/backend/, "")}>{children}</Link>;
      }
      // External links are not handled yet! In Future external links could be
      // augmentented with an "external" icon here.
    }
  };

const Copy_ = ({
  loading,
  children,
  className,
  loadingLines,
  maxHeight,
  isInline = false,
  element,
}: CopyProps) => {
  const [, setPreviewRoute] = usePreviewRouteParam();
  const { setRef, clamp, unclamp } = useVerticalLineClamp<
    HTMLSpanElement | HTMLDivElement
  >();
  useEffect(() => {
    if (maxHeight) {
      clamp();
    } else {
      unclamp();
    }
  }, [maxHeight, clamp, unclamp]);
  const handleOpenInPreview = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setPreviewRoute(e.currentTarget.getAttribute("href"));
    },
    [setPreviewRoute]
  );
  const loadingLinesArr = useMemo(
    () => [...Array(loadingLines || 5)],
    [loadingLines]
  );
  const El: any = element ? element : isInline ? "span" : "div";
  return (
    <El
      className={cx(styles.copy, {
        [className as string]: className,
        [css`
          max-height: ${maxHeight}px;
          overflow: hidden;
          > span {
            display: block;
          }
        `]: !!maxHeight,
      })}
      ref={setRef}
    >
      {loading ? (
        loadingLinesArr.map((v, i) => (
          <Skeleton key={i} width={i % 2 ? "80%" : "100%"} textLine />
        ))
      ) : children ? (
        <Interweave
          content={strikethroughUtf8ToHtml(children, true)}
          transform={transformFactory(handleOpenInPreview)}
        />
      ) : null}
    </El>
  );
};

export const Copy = memo(Copy_);
