
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Entity": [
      "BackgroundInfo",
      "BasicPage",
      "Biography",
      "GlossaryEntry",
      "MediaAudio",
      "MediaImage",
      "MediaRemoteVideo",
      "PeriodTaxonomy",
      "PeriodTerm",
      "Place",
      "RegionTaxonomy",
      "RegionTerm",
      "StationContent",
      "StationReference",
      "TopicTaxonomy",
      "TopicTerm",
      "TopicTour"
    ],
    "Media": [
      "MediaAudio",
      "MediaImage",
      "MediaRemoteVideo"
    ],
    "Node": [
      "BackgroundInfo",
      "BasicPage",
      "Biography",
      "GlossaryEntry",
      "Place",
      "TopicTour"
    ],
    "NodeWithImage": [
      "BackgroundInfo",
      "BasicPage",
      "Biography",
      "Place",
      "TopicTour"
    ],
    "NodeWithLocation": [
      "Place"
    ],
    "NodeWithStations": [
      "TopicTour"
    ],
    "NodeWithTaxonomy": [
      "Biography",
      "Place",
      "TopicTour"
    ],
    "Station": [
      "StationContent",
      "StationReference"
    ],
    "StationInterface": [
      "StationContent",
      "StationReference"
    ],
    "StationReferenceContent": [
      "Place"
    ],
    "Taxonomy": [
      "PeriodTaxonomy",
      "RegionTaxonomy",
      "TopicTaxonomy"
    ],
    "TaxonomyInterface": [
      "PeriodTaxonomy",
      "RegionTaxonomy",
      "TopicTaxonomy"
    ],
    "TaxonomyTerm": [
      "PeriodTerm",
      "RegionTerm",
      "TopicTerm"
    ],
    "TaxonomyTermInterface": [
      "PeriodTerm",
      "RegionTerm",
      "TopicTerm"
    ]
  }
};
      export default result;
    