import loadable from "@loadable/component";
import React from "react";
import { NonIndexRouteObject } from "react-router-dom";
import { BreadcrumbItemPropInterface } from "~/components/Breadcrumb";
import { NodeTeaser } from "~/components/NodeTeaser/NodeTeaser";
import {
  NodeType,
  NodeTypeScopeRoutes,
  ViewMode,
  nodeListingHeadings,
} from "~/constants";

import { NodeListingRouteProps } from "./NodeListingRoute";
import { NodeRouteProps } from "./NodeRoute";
import { NodeRouteBreadcrumbItem } from "./NodeRouteBreadcrumbItem";

const NodeRoute = loadable<NodeRouteProps>(() =>
  import("./NodeRoute").then((module) => module.NodeRoute)
);

const NodeListingRoute = loadable<NodeListingRouteProps>(() =>
  import("./NodeListingRoute").then((module) => module.NodeListingRoute)
);

const NotFoundRoute = loadable<unknown>(() =>
  import("./NotFoundRoute").then((module) => module.NotFoundRoute)
);

const HomeRoute = loadable<unknown>(() =>
  import("./HomeRoute").then((module) => module.HomeRoute)
);

const routeConfigFactory = ({
  name,
  nodeType,
  listDefaultMode = ViewMode.Text,
  fullDefaultMode = ViewMode.Text,
  fullHasNavMode,
  listHasNavMode,
  listFooterElement,
}: {
  name: string;
  nodeType: NodeType;
  listDefaultMode?: ViewMode;
  fullDefaultMode?: ViewMode;
  fullHasNavMode: boolean;
  listHasNavMode: boolean;
  listFooterElement?: React.ReactNode;
}) => [
  {
    path: NodeTypeScopeRoutes[nodeType],
    name,
    element: (
      <NodeListingRoute
        type={nodeType}
        defaultMode={listDefaultMode}
        hasNavMode={listHasNavMode}
        hasTextMode={true}
        footerElement={listFooterElement}
      />
    ),
    options: {
      filterable: true,
      shareable: true,
      favoritable: false,
    },
  },
  {
    path: `${NodeTypeScopeRoutes[nodeType]}/:name`,
    name: ":Name",
    element: (
      <NodeRoute
        type={nodeType}
        hasNavigationMode={fullHasNavMode}
        defaultMode={fullDefaultMode}
      />
    ),
    elementBreadcrumb: (props: BreadcrumbItemPropInterface) => (
      <NodeRouteBreadcrumbItem
        {...props}
        queryRoutePattern={`${NodeTypeScopeRoutes[nodeType]}/:name`}
      />
    ),
    options: {
      filterable: false,
      shareable: true,
      favoritable: true,
    },
  },
];

export interface RouteDefinition extends NonIndexRouteObject {
  path: string;
  name: string;
  element: React.ReactNode | null;
  elementBreadcrumb?: (
    props: BreadcrumbItemPropInterface
  ) => React.ReactNode | null;
  options: {
    filterable?: boolean;
    shareable?: boolean;
    favoritable?: boolean;
  };
}
const routeConfig_: RouteDefinition[] = [
  {
    path: "/",
    name: "Startseite",
    element: <HomeRoute />,
    options: {
      filterable: false,
      shareable: true,
      favoritable: false,
    },
  },

  ...routeConfigFactory({
    name: "Seiten",
    nodeType: NodeType.BasicPage,
    fullHasNavMode: false,
    listHasNavMode: false,
  }),
  ...routeConfigFactory({
    name: "Erkunden",
    nodeType: NodeType.Place,
    listDefaultMode: ViewMode.Nav,
    fullHasNavMode: true,
    listHasNavMode: true,
    fullDefaultMode: ViewMode.Nav,
  }),
  ...routeConfigFactory({
    name: "Informieren",
    nodeType: NodeType.BackgroundInfo,
    fullHasNavMode: false,
    listHasNavMode: false,
    listFooterElement: (
      <NodeTeaser
        hasImage={false}
        heading={nodeListingHeadings.GlossaryEntry.heading}
        content={nodeListingHeadings.GlossaryEntry.summary}
        href={"/" + NodeTypeScopeRoutes.GlossaryEntry}
      />
    ),
  }),
  ...routeConfigFactory({
    name: "Glossar",
    nodeType: NodeType.GlossaryEntry,
    fullHasNavMode: false,
    listHasNavMode: false,
    listFooterElement: (
      <NodeTeaser
        hasImage={false}
        heading={nodeListingHeadings.BackgroundInfo.heading}
        content={nodeListingHeadings.BackgroundInfo.summary}
        href={"/" + NodeTypeScopeRoutes.BackgroundInfo}
      />
    ),
  }),

  {
    path: "rundgaenge",
    name: "Rundgänge",
    element: (
      <NodeListingRoute
        hasNavMode={false}
        defaultMode={ViewMode.Text}
        hasTextMode
        type={NodeType.TopicTour}
      />
    ),
    options: {
      filterable: true,
      shareable: true,
      favoritable: false,
    },
  },

  {
    path: "rundgaenge/:name",
    name: ":Name",
    element: (
      <NodeRoute
        defaultMode={ViewMode.Text}
        type={NodeType.TopicTour}
        hasNavigationMode={true}
      />
    ),
    elementBreadcrumb: (props: BreadcrumbItemPropInterface) => (
      <NodeRouteBreadcrumbItem
        {...props}
        queryRoutePattern="rundgaenge/:name"
      />
    ),
    options: {
      filterable: false,
      shareable: true,
      favoritable: true,
    },
  },

  {
    path: "biografien",
    name: "Biografien",
    element: (
      <NodeListingRoute
        hasNavMode={false}
        defaultMode={ViewMode.Text}
        hasTextMode
        type={NodeType.Biography}
      />
    ),
    options: {
      filterable: true,
      shareable: true,
      favoritable: false,
    },
  },

  {
    path: "biografien/:name",
    name: ":Name",
    element: (
      <NodeRoute
        defaultMode={ViewMode.Text}
        type={NodeType.Biography}
        hasNavigationMode={false}
      />
    ),
    elementBreadcrumb: (props: BreadcrumbItemPropInterface) => (
      <NodeRouteBreadcrumbItem
        {...props}
        queryRoutePattern={"biografien/:name"}
      />
    ),
    options: {
      filterable: false,
      shareable: true,
      favoritable: true,
    },
  },

  {
    path: "*",
    name: "Seite nicht gefunden!",
    element: <NotFoundRoute />,
    options: {
      filterable: false,
      shareable: false,
      favoritable: false,
    },
  },
];

export const routeConfig = routeConfig_;
