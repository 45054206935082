export const useDisableContentScroll = (
  element: React.RefObject<HTMLElement>
): {
  startScroll: () => void;
  stopScroll: () => void;
  toggle: (enable: boolean) => void;
} => {
  const stopScroll = () => {
    if (!element.current) {
      return;
    }
    const scrollBarWidth =
      element.current.offsetWidth - element.current.scrollWidth;

    element.current.style.overflowY = "hidden";
    element.current.style.paddingRight = `${scrollBarWidth}px`;
  };

  const startScroll = () => {
    if (!element.current) {
      return;
    }
    element.current.style.overflowY = "";
    element.current.style.paddingRight = "";
  };

  const toggle = (enable: boolean) => {
    if (enable) {
      stopScroll();
    } else {
      startScroll();
    }
  };

  return { startScroll, stopScroll, toggle };
};
