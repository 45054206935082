import cx from "classnames";
import React, { memo } from "react";
import { Skeleton } from "~/components/Skeleton";

import * as styles from "./Heading.module.css";
import { strikethroughUtf8ToHtml } from "~/utils";

export interface HeadingProps {
  level?: 1 | 2 | 3 | 4;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "strong";
  children?: React.ReactNode;
  loading?: boolean;
  className?: string;
  isMuted?: boolean;
  srOnly?: boolean;
}

const Heading_ = ({
  level = 1,
  as,
  children,
  loading,
  className,
  isMuted,
  srOnly,
}: HeadingProps) => {
  const Element = as ?? "h1";
  const classes = cx({
    [styles.heading]: true,
    [styles.heading1]: level === 1,
    [styles.heading2]: level === 2,
    [styles.heading3]: level === 3,
    [styles.heading4]: level === 4,
    [styles.muted]: !!isMuted,
    [styles.srOnly]: srOnly,
    [className || ""]: !!className,
  });
  return (
    <Element className={classes}>
      {loading ? (
        <Skeleton placeholder={children} textLine className={styles.skeleton} />
      ) : typeof children === "string" ? (
        strikethroughUtf8ToHtml(children)
      ) : (
        children
      )}
    </Element>
  );
};

export const Heading = memo(Heading_);
