import { useStore } from "@nanostores/react";
import { lineString } from "@turf/helpers";
import { bbox, bboxPolygon, intersect } from "@turf/turf";
import differenceBy from "lodash/differenceBy";
import throttle from "lodash/throttle";
import { Map } from "mapbox-gl";
import { useCallback, useEffect, useMemo } from "react";
import { RegionTerm, useTaxonomiesQuery } from "~/generated";
import { $settings } from "~/stores";
import { setManualRegion, setRegions } from "../stores/settings";

const AUTO_LABEL = "-- automatisch --";
const AUTO_VALUE = "auto";

export const useRegions = () => {
  const { data, loading } = useTaxonomiesQuery({
    variables: { type: "regions" },
  });

  const { isManualRegion, regions } = useStore($settings);
  //const { startWatching, stopWatching } = useGeolocation();

  //useEffect(() => {
  //  if (!isManualRegion) {
  //    startWatching();
  //  } else {
  //    stopWatching();
  //  }
  //}, [isManualRegion, startWatching, stopWatching]);

  const allRegions = useMemo<RegionTerm[] | undefined>(() => {
    if (data && data.taxonomies && data.taxonomies[0]) {
      return data.taxonomies[0].terms as unknown as RegionTerm[];
    }
  }, [data]);

  const getRegionById = useCallback(
    (id: string) => {
      const selectedRegion = allRegions?.find((region) => region.id === id);
      return selectedRegion;
    },
    [allRegions]
  );

  const handleResetRegion = useCallback(() => {
    setManualRegion(false);
    setRegions([]);
  }, []);

  const handleRegionValueChange = useCallback(
    (value: string) => {
      if (value !== AUTO_VALUE) {
        const selectedRegion = allRegions?.find(
          (region) => region.id === value
        );
        if (selectedRegion) {
          setManualRegion(true);
          setRegions([selectedRegion]);
        } else {
          console.error(`Cannot find selected Region with ID ${value}!`);
        }
      } else {
        handleResetRegion();
      }
    },
    [allRegions, handleResetRegion]
  );

  const handleManualRegionChange = useCallback(
    ({ value }: { value: string }) => {
      handleRegionValueChange(value);
    },
    [handleRegionValueChange]
  );

  const regionOptions = useMemo(
    () =>
      allRegions &&
      allRegions
        .map((r) => ({ value: r.id, label: r.name }))
        .concat([{ label: AUTO_LABEL, value: AUTO_VALUE }]),
    [allRegions]
  );

  const regionIds = useMemo(
    () => regions && regions.map((r) => r.id),
    [regions]
  );

  const currentRegionValue = useMemo(
    () =>
      !loading && allRegions && regions
        ? {
            label: regions.length === 1 ? regions[0]?.name : AUTO_LABEL,
            value: regions.length === 1 ? regions[0]?.id : AUTO_VALUE,
          }
        : null,
    [loading, allRegions, regions]
  );

  const handleMapBoundingBox = useMemo(
    () =>
      throttle(
        (map: Map) => {
          if (!allRegions) {
            return;
          }
          // typings are wrong => ne = _ne
          const bounds = map.getBounds() as any;
          const viewportBbox = bbox(
            lineString([
              [bounds._ne.lng, bounds._ne.lat],
              [bounds._sw.lng, bounds._sw.lat],
            ])
          );
          const viewportBboxPolygon = bboxPolygon(viewportBbox);
          const regionsInViewport = allRegions.filter((region) => {
            const regionBbox = JSON.parse(region.bounding_box);
            return intersect(regionBbox, viewportBboxPolygon) != null;
          });

          const { regions } = $settings.get();

          const differenceToCurrent =
            regions.length !== regionsInViewport.length ||
            differenceBy(regions, regionsInViewport, "id").length;
          if (differenceToCurrent) {
            setRegions(regionsInViewport);
          }
          setManualRegion(false);
        },
        1000,
        { trailing: true }
      ),
    [allRegions]
  );
  // cleanup
  useEffect(
    () => () => handleMapBoundingBox && handleMapBoundingBox.cancel(),
    [handleMapBoundingBox]
  );

  return useMemo(
    () => ({
      getRegionById,
      regions,
      isManualRegion,
      hasRegions: regions && regions.length !== 0,
      regionOptions,
      currentRegionValue,
      loading,
      handleMapBoundingBox,
      handleManualRegionChange,
      handleRegionValueChange,
      handleResetRegion,
      allRegions,
      regionIds,
    }),
    [
      allRegions,
      currentRegionValue,
      getRegionById,
      handleManualRegionChange,
      handleRegionValueChange,
      handleResetRegion,
      handleMapBoundingBox,
      isManualRegion,
      loading,
      regionIds,
      regionOptions,
      regions,
    ]
  );
};
