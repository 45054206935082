import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  isMobile,
  isAndroid,
  isFirefox,
  isIOS,
  isOpera,
  browserVersion,
} from "mobile-device-detect";

export const platforms = {
  NATIVE: "native", // currently: Chrome, Edge mobile, Samsung internet
  FIREFOX: "firefox",
  FIREFOX_NEW: "firefox_new", // above version 79
  OPERA: "opera",
  IDEVICE: "idevice",
  OTHER: "other", // don't know, so will do nothing
} as const;

function getPlatform() {
  let platform: (typeof platforms)[keyof typeof platforms] = platforms.OTHER;
  if (window.hasOwnProperty("BeforeInstallPromptEvent")) {
    platform = platforms.NATIVE;
  } else if (isMobile && isAndroid && isFirefox && +browserVersion >= 79) {
    platform = platforms.FIREFOX_NEW;
  } else if (isMobile && isAndroid && isFirefox) {
    platform = platforms.FIREFOX;
  } else if (isOpera && isAndroid && isMobile) {
    platform = platforms.OPERA;
  } else if (isIOS && isMobile) {
    platform = platforms.IDEVICE;
  }

  return platform;
}

const platform = getPlatform();

const PWAInstallContext = createContext<{
  handleAutoInstall: () => any;
  handleRequestInstall: () => void;
  handleCloseInstallTooltip: () => void;
  platform:
    | "native"
    | "firefox"
    | "firefox_new"
    | "opera"
    | "idevice"
    | "other";
  hasVisibleInstallToolTip: boolean;
  isSupported: boolean;
  isInstalled: boolean;
}>({} as any);

export const usePWAInstallContext = () => useContext(PWAInstallContext);

export const PWAInstallProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [deferredPrompt, setDeferredPrompt] = useState<unknown | null>(null);
  const [hasVisibleInstallToolTip, setHasVisibleInstallToolTip] =
    useState<boolean>(false);

  const isSupported = useMemo(() => {
    if (deferredPrompt != null && platform === platforms.NATIVE) {
      return true;
    }
    if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      return true;
    }
    return false;
  }, [deferredPrompt]);

  const isInstalled = useMemo(() => {
    if (
      (window.navigator as any).standalone === true ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      return true;
    }
    return false;
  }, []);

  const handleAutoInstall = useCallback(() => {
    return (deferredPrompt as any).prompt().catch((err: any) => {
      console.error("Error occurred in the PWA installation process!", err);
    });
  }, [deferredPrompt]);

  const handleRequestInstall = useCallback(() => {
    if (deferredPrompt) {
      setHasVisibleInstallToolTip(false);
      handleAutoInstall();
    } else {
      setHasVisibleInstallToolTip(true);
    }
  }, [deferredPrompt, handleAutoInstall]);

  const handleBeforeInstallPromptEvent = useCallback((event: Event) => {
    event.preventDefault();
    setDeferredPrompt(event);
  }, []);

  const handleCloseInstallTooltip = useCallback(() => {
    setHasVisibleInstallToolTip(false);
  }, []);

  useEffect(() => {
    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPromptEvent
    );
    return function cleanup() {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPromptEvent
      );
    };
  }, [handleBeforeInstallPromptEvent]);

  const context = useMemo(
    () => ({
      handleAutoInstall,
      handleRequestInstall,
      handleCloseInstallTooltip,
      platform,
      hasVisibleInstallToolTip,
      isSupported,
      isInstalled,
    }),
    [
      handleAutoInstall,
      handleCloseInstallTooltip,
      handleRequestInstall,
      hasVisibleInstallToolTip,
      isInstalled,
      isSupported,
    ]
  );

  return (
    <PWAInstallContext.Provider value={context}>
      {children}
    </PWAInstallContext.Provider>
  );
};
