import { persistentMap } from "@nanostores/persistent";
import { action } from "nanostores";
import { RegionTerm } from "~/generated";
import { getData } from "~/utils";

type SettingState = {
  regions: RegionTerm[];
  isManualRegion: boolean;
  periods: string[];
  topics: string[];
};

const persistedSettings = getData<SettingState | undefined>("settings");
const initialState: SettingState = persistedSettings || {
  regions: [],
  isManualRegion: false,
  periods: [],
  topics: [],
};

export const $settings = persistentMap<SettingState>("settings", initialState, {
  encode: JSON.stringify,
  decode: JSON.parse,
});

export const setRegions = action(
  $settings,
  "setRegions",
  ($settings, regions: RegionTerm[]) => {
    $settings.setKey("regions", regions);
  }
);

export const setManualRegion = action(
  $settings,
  "setManualRegion",
  ($settings, value: boolean) => {
    $settings.setKey("isManualRegion", value);
  }
);

export const setTopics = action(
  $settings,
  "setTopics",
  ($settings, topics: string[]) => {
    $settings.setKey("topics", topics);
  }
);

export const setPeriods = action(
  $settings,
  "setPeriods",
  ($settings, periods: string[]) => {
    $settings.setKey("periods", periods);
  }
);
