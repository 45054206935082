import React, { memo } from "react";
import cx from "classnames";
import * as styles from "./TopBar.module.css";
import { Link } from "react-router-dom";

type TopBarButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  title: string;
  isActive: boolean;
  href?: string;
};

const TopBarButton_ = ({
  children,
  onClick = () => {},
  title,
  isActive,
  href,
}: TopBarButtonProps) => {
  const Element = !!href ? Link : "button";
  const classes = cx({
    [styles.button]: true,
    [styles.buttonIsActive]: isActive,
  });
  return (
    <Element
      to={href as string}
      className={classes}
      onClick={onClick}
      title={title}
    >
      {children}
    </Element>
  );
};

export const TopBarButton = memo(TopBarButton_);
