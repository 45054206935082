import { NodeType } from "./nodeTypes";

export const NodeTypeScopeRoutes = {
  [NodeType.BackgroundInfo]: "informieren",
  [NodeType.Place]: "erkunden",
  [NodeType.TopicTour]: "rundgaenge",
  [NodeType.Biography]: "biografien",
  [NodeType.BasicPage]: "seite",
  [NodeType.GlossaryEntry]: "glossar",
};
