import { css } from "@emotion/css";
import { forwardRef, memo } from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { Copy } from "~/components/Copy";
import { Heading } from "~/components/Heading";
import { useRouteQuery } from "~/generated";

const NodePreview_ = forwardRef<HTMLAnchorElement, { route: string }>(
  ({ route }, ref) => {
    const { data, loading } = useRouteQuery({
      variables: { path: route },
    });

    return (
      <Link
        to={route}
        replace={true}
        ref={ref}
        className={css`
          max-width: var(--page-max-width);
          margin: 0 auto;
          padding: var(--margin-lg) var(--margin-md);
          text-decoration: none;
          display: block;
          user-select: none;
          user-drag: none;
          -webkit-user-drag: none;
          -moz-user-drag: none;
        `}
      >
        <Heading level={2} loading={loading}>
          {!data?.node?.name && loading
            ? "Lorem ipsum dolor sit"
            : data?.node?.name}
        </Heading>
        <Copy loading={loading} loadingLines={3} maxHeight={70}>
          {data?.node?.summary}
        </Copy>
        <div
          className={css`
            margin-top: var(--margin-md);
          `}
        >
          <div
            className={css`
              color: var(--color-text);
              font-weight: var(--font-weight-bold);
              font-size: var(--font-size-md);
              text-underline-offset: 0.2em;
              text-decoration: underline;
              display: flex;
              align-items: center;
            `}
          >
            <ArrowRight strokeWidth={3} size={16} />
            <span
              className={css`
                margin-left: var(--margin-sm);
                color: inherit;
                display: inline-block;
                &:hover {
                  background-color: var(--color-text);
                  color: var(--color-bg);
                  text-decoration: none;
                  outline: 1px solid var(--color-text);
                }
                &:focus,
                &:active {
                  outline: 4px solid var(--color-bg-alt);
                  outline-offset: var(--margin-xs);
                }
              `}
            >
              Weiterlesen
            </span>
          </div>
        </div>
      </Link>
    );
  }
);
NodePreview_.displayName = "NodePreview";
export const NodePreview = memo(NodePreview_);
