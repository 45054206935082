import { css } from "@emotion/css";
import { FallbackProps } from "react-error-boundary";
import { Frown } from "react-feather";
import { Heading } from "../Heading";
import { Page } from "../Page";

export const ErrorPage = ({
  title = "Die Seite konnte nicht geladen werden!",
  heading = "Die Seite konnte nicht geladen werden!",
  description = "Falls der Fehler weiterhin auftritt, wenden Sie sich an verband[at]sinti-roma-hessen.de",
}: {
  title?: string;
  heading?: string;
  description?: string | null;
  children?: React.ReactNode | undefined;
} & Partial<FallbackProps>) => (
  <Page heading={title} hideHeading isCentered>
    <div
      className={css`
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <div>
        <div
          className={css`
            margin-bottom: var(--margin-md);
          `}
        >
          <Frown width="2em" height="2em" color="var(--color-text-muted2)" />
        </div>
        <Heading level={4} as="strong">
          {heading}
        </Heading>
        {description && (
          <p
            className={css`
              color: var(--color-text-muted);
              font-size: var(--font-size-sm);
              max-width: 20em;
            `}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  </Page>
);
