import React, { memo } from "react";
import cx from "classnames";
import * as styles from "./BottomBar.module.css";
import { css } from "@emotion/css";

type BottomBarButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  title: string;
  enabled: boolean;
  isActive: boolean;
  hasHint?: boolean;
};

const BottomBarButton_ = ({
  children,
  onClick,
  title,
  enabled,
  isActive,
  hasHint,
}: BottomBarButtonProps) => {
  const classes = cx({
    [styles.button]: true,
    [styles.buttonIsActive]: isActive,
  });
  return (
    <button
      disabled={!enabled}
      className={classes}
      onClick={onClick}
      title={title}
    >
      <span
        className={css`
          position: relative;
          line-height: 1em;
          align-items: center;
          display: flex;
        `}
      >
        <span
          className={css`
            width: var(--margin-md);
            height: var(--margin-md);
            display: block;
            background: var(--color-text-alt);
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 100%;
            transform: scale(${hasHint ? 1 : 0});
            transition: transform 300ms ease-in-out;
          `}
        />
        {children}
      </span>
    </button>
  );
};

export const BottomBarButton = memo(BottomBarButton_);
