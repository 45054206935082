import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { forwardRef, memo, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ErrorWrapper } from "~/components/ErrorPage";
import { useDisableContentScroll } from "~/hooks";
import { mergeRefs } from "~/utils/mergeRefs";
import styles from "./ContentArea.module.css";

type ContentAreaProps = {
  children: React.ReactNode;
  isScrollable: boolean;
  isOverlayed: boolean;
};

const ContentArea_ = forwardRef<HTMLDivElement, ContentAreaProps>(
  ({ children, isScrollable, isOverlayed }, ref) => {
    // (Un-)Apply the scroll freze to the content area.
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { toggle: toggleScrollFreeze } =
      useDisableContentScroll(scrollContainerRef);
    useEffect(() => {
      toggleScrollFreeze(!isScrollable);
    }, [isScrollable, toggleScrollFreeze]);
    const location = useLocation();

    // Scroll elements defined by hash fragment into view
    useEffect(() => {
      if (scrollContainerRef.current) {
        if (typeof scrollContainerRef.current.scroll === "function") {
          scrollContainerRef.current.scroll({ top: 0 });
        }
      }
    }, [location, isScrollable]);

    const [{ opacity }, api] = useSpring(() => ({
      opacity: 1,
      config: {
        clamp: true,
      },
    }));
    useEffect(() => {
      api.start({
        opacity: isOverlayed ? 1 : 0,
        config: { duration: 100 },
      });
    }, [isOverlayed, api]);
    const overlayStyle = {
      display: opacity.to((v) => (v === 0 ? "none" : "block")),
      opacity,
    };

    return (
      <ErrorWrapper>
        <div
          ref={mergeRefs([scrollContainerRef, ref])}
          className={styles.content}
        >
          <animated.div className={styles.overlay} style={overlayStyle} />
          {children}
        </div>
      </ErrorWrapper>
    );
  }
);

ContentArea_.displayName = "ContentArea";

export const ContentArea = memo(ContentArea_);
