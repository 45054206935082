import { css } from "@emotion/css";
import React, { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

export const OverlayTooltip = ({
  children,
  isOpen,
  onClose,
}: {
  children: React.ReactNode | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const overlayRef = useRef(null);

  useOnClickOutside(overlayRef, onClose);

  return (
    <>
      {isOpen ? (
        <div
          ref={overlayRef}
          className={css`
            position: absolute;
            bottom: var(--margin-md);
            left: 0;
            right: 0;
            z-index: 31;
            background: var(--color-bg);
            margin: 0 var(--margin-lg) var(--margin-md) var(--margin-lg);
            padding: var(--margin-md);
            &:after {
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%, 0);
              content: "";
              display: block;
              width: 1px;
              height: 1px;
              border: var(--margin-md) solid transparent;
              border-top-color: var(--color-bg);
            }
          `}
        >
          {children}
        </div>
      ) : null}
    </>
  );
};
