import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import React from "react";
import { matomoSiteId, matomoUrl } from "~/constants";

const instance =
  matomoUrl &&
  createInstance({
    urlBase: matomoUrl,
    siteId: +matomoSiteId,
    linkTracking: true,

    configurations: {
      disableCookies: true,
    },
  });

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode | null;
}) =>
  instance ? (
    // @ts-ignore
    <MatomoProvider value={instance}>{children}</MatomoProvider>
  ) : (
    children
  );
