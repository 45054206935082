import { css } from "@emotion/css";
import cx from "classnames";
import React, { memo } from "react";
import { Heading } from "~/components/Heading";

export interface PageProps {
  heading?: string;
  children: React.ReactNode;
  loading?: boolean;
  hideHeading?: boolean;
  className?: string;
  classNameOuter?: string;
  surtitle?: React.ReactElement;
  summary?: string;
  isCentered?: boolean;
}

const Page_ = ({
  heading,
  children,
  loading,
  hideHeading,
  className,
  surtitle,
  summary,
  isCentered,
}: PageProps) => {
  return (
    <main
      className={cx({
        [css`
          position: relative;
          width: 100%;
          min-height: 100%;
        `]: true,
        [className || ""]: !!className,
      })}
    >
      <div
        className={
          !isCentered
            ? ""
            : css`
                max-width: var(--page-max-width);
                margin: 0 auto;
                width: 100%;
                height: 100%;
                position: relative;
                padding: 0 var(--margin-md);
              `
        }
      >
        {surtitle}
        <header
          className={
            !(hideHeading && !summary)
              ? css`
                  margin: var(--margin-lg) 0;
                `
              : ""
          }
        >
          <Heading level={1} as="h1" loading={loading} srOnly={hideHeading}>
            {!heading || loading ? "Lorem ipsum dolor sit" : heading}
          </Heading>
          {summary && (
            <p
              className={css`
                font-size: var(--font-size-lg);
                margin: 0;
              `}
            >
              {summary}
            </p>
          )}
        </header>
        {children}
      </div>
    </main>
  );
};

export const Page = memo(Page_);
