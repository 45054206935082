import { css } from "@emotion/css";
import { memo } from "react";
import { useTaxonomiesQuery } from "~/generated";
import { CheckRadio } from "../CheckRadio";
import { Heading } from "../Heading";
import { useStore } from "@nanostores/react";
import { $settings, setPeriods, setTopics } from "~/stores";

const TaxonomyFilters = ({
  type,
  onChange,
  values = [],
}: {
  type: "periods" | "topics";
  onChange?: (values: string[]) => void;
  values?: string[];
}) => {
  const { data, loading } = useTaxonomiesQuery({
    variables: { type },
  });
  const taxonomy = data && data.taxonomies && data.taxonomies[0];
  const terms = loading ? [...Array(3)] : taxonomy!.terms!;

  return (
    <section
      className={css`
        margin-bottom: var(--margin-xl);
      `}
    >
      <Heading level={4} loading={loading}>
        {!loading ? taxonomy?.name : "Dolor sit amet"}
      </Heading>
      {terms.map((term, i) => (
        <CheckRadio
          className={css`
            margin-bottom: var(--margin-md);
          `}
          onChange={
            !term
              ? undefined
              : (isActive: boolean) => {
                  if (!onChange) {
                    return;
                  }
                  const valSet = new Set(values);
                  const method = isActive ? "add" : "delete";
                  valSet[method](term.id);

                  onChange([...valSet]);
                }
          }
          loading={loading}
          type="checkbox"
          key={i}
          label={term && term.name}
          checked={term && values.includes(term.id)}
          value={term && term.id}
        />
      ))}
    </section>
  );
};

const Filters_ = () => {
  const { periods, topics } = useStore($settings);
  return (
    <>
      <Heading level={2}>Filter</Heading>
      <TaxonomyFilters type="periods" values={periods} onChange={setPeriods} />
      <TaxonomyFilters type="topics" values={topics} onChange={setTopics} />
    </>
  );
};
export const Filters = memo(Filters_);
