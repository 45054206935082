import { Location } from "history";
import { matchPath, useLocation } from "react-router-dom";

export const useRoutePath = (pattern?: string): string => {
  const { pathname: path }: Location = useLocation();
  const resolvedQueryPathMatch =
    pattern && matchPath({ path: pattern, end: false }, path);
  const routePath = resolvedQueryPathMatch
    ? resolvedQueryPathMatch.pathname
    : path;

  return routePath;
};
