import { css, cx } from "@emotion/css";
import { memo } from "react";
import { Button } from "~/components/Button";

const ToggleButton_ = ({
  onClick,
  className,
  label,
  iconElement,
}: {
  iconElement: React.ReactNode;
  label: string;
  className: string;
  onClick: () => void;
}) => (
  <Button
    className={cx(
      className,
      css`
        --button-padding: var(--margin-xs);
      `
    )}
    iconClassName={css`
      margin-right: 0 !important;
    `}
    icon={iconElement}
    label={label}
    onClick={onClick}
    level={1}
    size="md"
    alignment="center"
  />
);
export const ToggleButton = memo(ToggleButton_);
