import { useCallback, useMemo } from "react";
import {
  createSearchParams,
  NavigateOptions,
  useSearchParams,
} from "react-router-dom";
import { urlDecode, urlEncode } from "~/utils";

/**
 * This custom hook is a wrapper around `useSearchParams()` that parses and
 * serializes the search param value using the JSURL library, which permits any
 * JavaScript value to be safely URL-encoded.
 *
 * It's a good example of how React hooks offer a great deal of flexibility when
 * you compose them together!
 *
 * TODO: rethink the generic type here, users can put whatever they want in the
 * URL, probably best to use runtime validation with a type predicate:
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export const useQueryParam = <T>(
  key: string
): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = useMemo(
    () => (paramValue ? urlDecode<T>(paramValue) : undefined),
    [paramValue]
  );

  const setValue = useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const newSearchParams = createSearchParams(searchParams);
      newSearchParams.set(key, urlEncode(newValue));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
};
