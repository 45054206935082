import { persistentMap } from "@nanostores/persistent";
import { action } from "nanostores";
import { ViewState } from "react-map-gl";

export const $mapViewState = persistentMap<ViewState>(
  "mapViewState",
  {} as ViewState,
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  }
);

export const setMapViewState = action(
  $mapViewState,
  "setMapViewState",
  ($mapViewState, state: ViewState) => {
    $mapViewState.set(state);
  }
);
