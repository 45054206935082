import { css } from "@emotion/css";
import { memo } from "react";
import { ArrowUp, X } from "react-feather";
import { iconStrokeWidth } from "~/constants";
import { ToggleButton } from "./ToggleButton";

const ToggleableSlideup_ = ({
  children,
  onToggle,
  label,
  isInteractive = true,
  actionType = "close",
}: {
  children: React.ReactNode;
  onToggle: () => void;
  label: string;
  isInteractive?: boolean;
  actionType: "close" | "open";
}) => (
  <div
    className={css`
      position: relative;
    `}
  >
    {isInteractive && (
      <ToggleButton
        label={label}
        onClick={onToggle}
        className={css`
          position: absolute;
          right: var(--margin-md);
          transform: translateY(-50%);
        `}
        iconElement={
          actionType === "close" ? (
            <X strokeWidth={iconStrokeWidth} />
          ) : (
            <ArrowUp strokeWidth={iconStrokeWidth} />
          )
        }
      />
    )}
    {children}
  </div>
);
export const ToggleableSlideup = memo(ToggleableSlideup_);
