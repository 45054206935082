import { useEffect, useRef } from "react";

// See: https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
import { useIsomorphicLayoutEffect } from "usehooks-ts";

// Adapted from https://usehooks-ts.com/react-hook/use-timeout
export const useEffectAfterTimeout = (
  callback: () => void,
  delay: number | null,
  depList: any[] = []
) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    let resultOfCall: unknown;
    const id = setTimeout(() => {
      resultOfCall = savedCallback.current();
    }, delay);

    return () => {
      clearTimeout(id);
      if (typeof resultOfCall === "function") {
        resultOfCall();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...depList]);
};
