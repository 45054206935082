/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo } from "react";
import { Home, Menu, Settings } from "react-feather";
import { useLocation } from "react-router-dom";
import { Logo } from "~/components/Logo";
import { iconStrokeWidth } from "~/constants";

import { toggleMenuDrawer, toggleOptionsDrawer, useUiState } from "~/stores";
import * as styles from "./TopBar.module.css";
import { TopBarButton } from "./TopBarButton";

const homePath = "/";
const helpPath = "/seite/hilfe";

const TopBar_ = () => {
  const { drawerState } = useUiState();
  const { pathname } = useLocation();

  return (
    <header className={styles.topBar}>
      <h1 className={styles.logo}>
        <TopBarButton isActive={false} title="Startseite" href={homePath}>
          <Logo variant="light" />
        </TopBarButton>
      </h1>
      <nav className={styles.nav}>
        <TopBarButton
          title="Startseite"
          href={homePath}
          isActive={pathname === homePath && drawerState === "Closed"}
        >
          <Home strokeWidth={iconStrokeWidth} />
        </TopBarButton>
        <TopBarButton
          title="Einstellungen"
          onClick={toggleOptionsDrawer}
          isActive={drawerState === "Options"}
        >
          <Settings strokeWidth={iconStrokeWidth} />
        </TopBarButton>
        <TopBarButton
          title="Hilfe"
          href={helpPath}
          isActive={pathname === helpPath && drawerState === "Closed"}
        >
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle cx="12" cy="12" r="10" strokeWidth="1" />
              <path
                d="m9.09 8.125c1.121-3.186 5.835-2.377 5.83 1 0 2.704-2.942 2.213-2.945 3.257l-0.0039 1.09"
                strokeWidth="1"
              />
              <line x1="12" x2="12.01" y1="16.57" y2="16.57" strokeWidth="2" />
            </g>
          </svg>
        </TopBarButton>
        <TopBarButton
          title="Menü"
          onClick={toggleMenuDrawer}
          isActive={drawerState === "Menu"}
        >
          <Menu strokeWidth={iconStrokeWidth} />
        </TopBarButton>
      </nav>
    </header>
  );
};

export const TopBar = memo(TopBar_);
