import cx from "classnames";
import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import * as styles from "./Breadcrumb.module.css";
import { strikethroughUtf8ToHtml } from "~/utils";

export type BreadcrumbItemPropInterface = {
  isLink: boolean;
  className: string;
};

type BreadcrumbProps = {
  entries: {
    path: string;
    name: string;
    Element?: (props: BreadcrumbItemPropInterface) => React.ReactNode;
  }[];
};
const Breadcrumb_ = ({ entries = [] }: BreadcrumbProps) => {
  const domRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      node.scrollLeft = 99999;
    }
  }, []);
  return (
    <nav
      className={styles.breadcrumb}
      aria-label="Position in der App-Struktur"
    >
      <div ref={domRef} className={styles.scrollContainer}>
        <ul className={styles.ul}>
          {entries.map(({ path, name, Element }, i) => {
            const isLink = entries.length !== i + 1;
            const hasComponent = typeof Element !== "undefined";
            const className = cx({
              [styles.item]: true,
              [styles.a]: isLink,
            });
            return (
              <li key={i} className={styles.li}>
                {!hasComponent ? (
                  !isLink ? (
                    <span className={className}>
                      {strikethroughUtf8ToHtml(name)}
                    </span>
                  ) : (
                    <Link className={className} to={path} replace={false}>
                      {strikethroughUtf8ToHtml(name)}
                    </Link>
                  )
                ) : (
                  Element({ isLink, className })
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export const Breadcrumb = memo(Breadcrumb_);
