import { useLocation } from "react-router-dom";
import { useEffectAfterTimeout } from "~/hooks";

export const Analytics = () => {
  const location = useLocation();
  // Use the timeout effect function to track after 500ms, this prevents
  // redirect paths from being tracked.
  useEffectAfterTimeout(
    () => {
      // the useMatomo thing doesn't work for some reason.
      if (
        import.meta.env.MODE !== "development" &&
        typeof window._paq !== "undefined"
      ) {
        window._paq.push(["trackPageView"]);
      }
    },
    500,
    [location]
  );

  return null;
};
