import { css } from "@emotion/css";
import { MoreVertical, PlusSquare, Share } from "react-feather";
import { iconStrokeWidth } from "~/constants";

export const PWAInstallHints = ({
  platform,
}: {
  platform:
    | "native"
    | "firefox"
    | "firefox_new"
    | "opera"
    | "idevice"
    | "other";
}) => {
  if (platform === "idevice") {
    return (
      <p className={wrapperClassName}>
        Tippe auf
        <Share className={iconClassName} strokeWidth={iconStrokeWidth} /> im
        Browser und dann auf{" "}
        <PlusSquare className={iconClassName} strokeWidth={iconStrokeWidth} />{" "}
        »zum Home-Bildschirm hinzufügen«
      </p>
    );
  }

  if (platform === "firefox_new") {
    return (
      <p className={wrapperClassName}>
        Öffne das{" "}
        <MoreVertical className={iconClassName} strokeWidth={iconStrokeWidth} />{" "}
        Menu im Browser und tippe auf »Installieren«
      </p>
    );
  }

  return (
    <p className={wrapperClassName}>
      Öffne das{" "}
      <MoreVertical className={iconClassName} strokeWidth={iconStrokeWidth} />{" "}
      Menu im Browser und tippe auf »Zum Startbildschirm hinzufügen«
    </p>
  );
};

const iconClassName = css`
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  vertical-align: text-bottom;
`;
const wrapperClassName = css`
  margin: 0;
`;
