import React, { forwardRef, memo, PropsWithChildren } from "react";
import cx from "classnames";
import * as styles from "./FormElement.module.css";
import { Skeleton } from "../Skeleton";

type FormElementProps = PropsWithChildren<{
  label?: string;
  labelAfter?: boolean;
  flow?: "horizontal" | "vertical";
  labelStyle?: "default" | "normal";
  className?: string;
  loading?: boolean;
}>;

const FormElement_ = forwardRef<HTMLDivElement, FormElementProps>(
  (
    {
      label,
      children,
      labelAfter,
      flow = "vertical",
      labelStyle = "default",
      className = "",
      loading,
    },
    ref
  ) => (
    <div className={cx(styles.formElement, className)} ref={ref}>
      <label
        className={cx({
          [styles.label]: true,
          [styles.labelAfter]: labelAfter,
          [styles.labelHorizontal]: flow === "horizontal",
          [styles.labelStyleNormal]: labelStyle === "normal",
          [styles.labelStyleDefault]: labelStyle === "default",
        })}
      >
        {loading ? (
          <Skeleton
            className={styles.labelText}
            textLine
            width={Math.floor(Math.max(Math.random(), 0.5) * 10) + "em"}
          ></Skeleton>
        ) : (
          <span className={styles.labelText}>{label}</span>
        )}
        {children}
      </label>
    </div>
  )
);

FormElement_.displayName = "FormElement";

export const FormElement = memo(FormElement_);
