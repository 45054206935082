import { Link } from "react-router-dom";
import { BreadcrumbItemPropInterface } from "~/components/Breadcrumb";
import { Skeleton } from "~/components/Skeleton";
import { useRouteQuery } from "~/generated/graphql";
import { useRoutePath } from "~/hooks";
import { strikethroughUtf8ToHtml } from "~/utils/nodes";

type NodeRouteBreadcrumbItemProps = {
  queryRoutePattern?: string;
};
export const NodeRouteBreadcrumbItem = ({
  queryRoutePattern,
  className,
  isLink,
}: NodeRouteBreadcrumbItemProps & BreadcrumbItemPropInterface) => {
  const path = useRoutePath(queryRoutePattern);
  const { data, loading } = useRouteQuery({
    variables: { path },
  });

  return loading ? (
    <span className={className}>
      <Skeleton textLine placeholder="Imaginary name" />
    </span>
  ) : isLink ? (
    <Link to={path} className={className}>
      <>{data?.node?.name ? strikethroughUtf8ToHtml(data?.node?.name) : null}</>
    </Link>
  ) : (
    <span className={className}>
      {data?.node?.name ? strikethroughUtf8ToHtml(data?.node?.name) : null}
    </span>
  );
};
