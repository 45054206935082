import { css } from "@emotion/css";
import cx from "classnames";
import React, { memo, useMemo } from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { Copy } from "~/components/Copy";
import { Heading } from "~/components/Heading";
import { Image } from "~/components/Image";
import { iconStrokeWidth } from "~/constants";
import { ImageDefinition } from "~/constants/images";
import * as styles from "./NodeTeaser.module.css";

export interface NodeTeaserProps {
  heading?: string;
  content?: string;
  children?: React.ReactNode;
  loading?: boolean;
  href?: string;
  image?: ImageDefinition;
  hasImage: boolean;
}

const NodeTeaser_ = ({
  heading,
  content,
  loading,
  href,
  image,
  hasImage,
}: NodeTeaserProps) => {
  const linkClasses = cx({
    [styles.link]: true,
    [styles.linkDisabled]: !href || loading,
  });
  const LinkWrapper = useMemo(() => {
    const wrapper: React.FunctionComponent<{
      children?: React.ReactNode | null;
    }> = ({ children }: { children?: React.ReactNode | null }) =>
      href ? (
        <Link className={linkClasses} to={href}>
          {children}
        </Link>
      ) : (
        <>{children}</>
      );
    wrapper.displayName = "LinkWrapper";
    return wrapper;
  }, [href, linkClasses]);
  return (
    <article
      className={css`
        margin-bottom: var(--margin-lg);
        margin-top: var(--margin-lg);
        padding: var(--margin-md) 0;
        color: var(--color-text);
      `}
    >
      <LinkWrapper>
        <Heading level={3} as="h3" loading={loading}>
          {!heading || loading ? "Lorem ipsum dolor sit" : heading}
        </Heading>
        {hasImage && (loading || image) && (
          <Image
            type="4By3"
            data={image}
            loading={loading}
            className={css`
              display: flex;
              margin-bottom: var(--margin-md);
            `}
          />
        )}
        <Copy loading={loading} loadingLines={3} isInline>
          {content}
        </Copy>
        {!loading && (
          <ArrowRight
            className={css`
              display: inline-block;
              vertical-align: middle;
              transform: translateX(calc(var(--margin-md)));
              height: calc(var(--line-height) * 1em);
              position: absolute;
            `}
            strokeWidth={iconStrokeWidth}
            height="1em"
            width="1em"
          />
        )}
      </LinkWrapper>
    </article>
  );
};

export const NodeTeaser = memo(NodeTeaser_);
