import { NodeType } from "~/constants";
import graphqlSchema from "~/generated/graphql-fragments";

export const nodeTypesListingHaveImage = (types: NodeType[]): boolean =>
  types.filter((type) =>
    [
      NodeType.Place,
      NodeType.BackgroundInfo,
      NodeType.TopicTour,
      NodeType.Biography,
    ].includes(type)
  ).length !== 0;

export const nodeTypeHasImage = (type: NodeType): boolean =>
  [NodeType.Place, NodeType.BackgroundInfo, NodeType.Biography].includes(type);

export const nodeTypeHasTaxonomies = (type: NodeType): boolean =>
  graphqlSchema.possibleTypes.NodeWithTaxonomy.includes(type);
// duplicate
export const nodeTypeIsRegionSpecific = (type: NodeType) =>
  nodeTypeHasTaxonomies(type);

export const nodeTypeHasMap = (type: NodeType): boolean =>
  type === NodeType.TopicTour;
// duplicate
export const nodeHasNavMode = (type: NodeType) => type === NodeType.TopicTour;

export const nodeTypeHasLocation = (type: NodeType): boolean =>
  type === NodeType.Place;

export const strikethroughUtf8ToHtml = <T extends boolean>(
  label: string,
  toString?: T
): T extends true ? string : JSX.Element => {
  // The opposite is in the update hook in:
  // web/backend/modules/custom/siro_app_update_hooks/siro_app_update_hooks.module
  const replacements = {
    Z̶i̶g̶e̶u̶n̶e̶r̶n̶: "<s>Zigeunern</s>",
    Z̶i̶g̶e̶u̶n̶e̶r̶: "<s>Zigeuner</s>",
    z̶i̶g̶e̶u̶n̶e̶r̶: "<s>zigeuner</s>",
    z̶i̶g̶e̶u̶n̶e̶r̶n̶: "<s>zigeunern</s>",
  };

  for (const [needle, str] of Object.entries(replacements)) {
    label = label.replaceAll(needle, str);
  }

  if (toString) {
    // @ts-ignore
    return label as string;
  }

  // @ts-ignore
  return <span dangerouslySetInnerHTML={{ __html: label }}></span>;
};
