import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ErrorPage } from "./ErrorPage";

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info.componentStack);
};

export const ErrorWrapper = ({
  children,
}: {
  children: React.ReactNode | undefined;
}) => (
  <ErrorBoundary
    onError={logError}
    FallbackComponent={ErrorPage as React.ComponentType<FallbackProps>}
  >
    {children}
  </ErrorBoundary>
);
