import React, { memo, useCallback, useMemo } from "react";
import "./Select.scss";
import { FormElement } from "~/components/FormElement";
import ReactSelect, { OnChangeValue } from "react-select";
import { useElementSize } from "usehooks-ts";

const customStyles = {
  control: () => ({}),
  menu: () => ({}),
  singleValue: () => ({}),
  multiValue: () => ({}),
  group: () => ({}),
  container: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  input: () => ({}),
  dropdownIndicator: () => ({}),
  clearIndicator: () => ({}),
  indicatorSeperator: () => ({}),
};

type SelectProps<T, IsMulti extends boolean> = {
  className?: string;
  label: string;
  onChange: (val: OnChangeValue<T, IsMulti>) => void;
  value: T | T[];
  options: ReadonlyArray<{
    value: T;
    label: string;
    isSelected?: boolean;
  }>;
  multiple?: IsMulti;
  placeholder?: string;
  isSearchable?: boolean;
  isUsingPortal?: boolean;
};

const Select_ = <T, IsMulti extends boolean = false>({
  value,
  onChange,
  label,
  multiple = false as IsMulti,
  options,
  placeholder = "-- Auswählen --",
  isSearchable = false,
  isUsingPortal = false,
}: SelectProps<T, IsMulti>) => {
  const customComponents = useMemo(
    () => ({
      Placeholder: () => (
        <span className="select__placeholder">{placeholder}</span>
      ),
      NoOptionsMessage: () => (
        <span className="select__menu-notice">Keine Optionen gefunden!</span>
      ),
    }),
    [placeholder]
  );
  const handleChange = useCallback(
    (newVal: OnChangeValue<T, IsMulti>) => {
      onChange(newVal);
    },
    [onChange]
  );
  const [containerRef, { width }] = useElementSize();
  return (
    <FormElement label={label} ref={containerRef}>
      <ReactSelect
        isSearchable={isSearchable}
        components={customComponents}
        options={options as any}
        value={value}
        onChange={handleChange}
        isMulti={multiple}
        styles={{
          ...customStyles,
          menuPortal: (styles) => ({
            ...styles,
            "--select-menu-width": width + "px",
          }),
        }}
        className={"select"}
        classNamePrefix={"select"}
        menuPortalTarget={
          isUsingPortal
            ? document.getElementById("select-portal-root")
            : undefined
        }
      />
    </FormElement>
  );
};
// casting is necessary to preserve generics
// @see https://stackoverflow.com/questions/56891234/reacts-memo-drops-generics-in-the-returned-function
export const Select = memo(Select_) as typeof Select_;
