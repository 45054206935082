import { map } from "nanostores";

export type Geolocation = {
  position: {
    latitude?: number;
    longitude?: number;
    accuracy?: number;
    speed?: number | null;
    heading?: number | null;
    timestamp?: number;
  };
  watchCounter: number;
};

const initialState: Geolocation = {
  watchCounter: 0,
  position: {},
};

export const $geolocation = map(initialState);
