import cx from "classnames";
import React, { memo, useMemo } from "react";

import * as styles from "./Skeleton.module.css";

type SkeletonProps = {
  className?: string;
  width?: number | string;
  height?: number | string;
  children?: React.ReactNode;
  placeholder?: React.ReactNode;
  textLine?: boolean;
};

const Skeleton_ = (props: SkeletonProps) => {
  const { className, height, width, placeholder, children, textLine } = props;
  const hasChildren = Boolean(children || placeholder);
  const width_ = useMemo(
    () => (hasChildren ? "auto" : width),
    [width, hasChildren]
  );
  const classes = useMemo(
    () =>
      cx({
        [styles.skeleton]: true,
        [styles.skeletonWithChildren]: hasChildren,
        [styles.skeletonFitContent]: hasChildren && !width,
        [styles.skeletonHeightAuto]: hasChildren && !height,
        [styles.textLine]: textLine,
        [className || ""]: !!className,
      }),
    [hasChildren, width, className, height, textLine]
  );
  const styleObj = useMemo(
    () => ({
      width: width_,
      height,
    }),
    [width_, height]
  );
  return (
    <span className={classes} style={styleObj} aria-hidden="true">
      {placeholder ? <span>{placeholder}</span> : children}
    </span>
  );
};

export const Skeleton = memo(Skeleton_);
