import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import result from "../generated/graphql-fragments";

const httpLink = new HttpLink({
  useGETForQueries: false,
  uri: "/backend/api",
});
const hasSubtleCrypto = typeof globalThis.crypto.subtle !== "undefined";
// never use APQ for now because this is broken in Drupal.
const useAPQ = false;
// Fall back to standard HttpLink when FF in dev mode.
const linkChain = useAPQ
  ? createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
    }).concat(httpLink)
  : httpLink;

if (useAPQ && !hasSubtleCrypto) {
  console.warn(
    "window.crypto.suble does not exist! Falling back to graphql using POST requests!"
  );
}

const client = new ApolloClient({
  link: linkChain,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    resultCaching: true,
    possibleTypes: result.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          nodes: {
            // from here https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L28
            // forked this because we have the additional "items" property
            keyArgs: ["types", "filter"],
            merge: (existing: any, incoming: any, { args }: any) => {
              const incomingItems = incoming.items;
              const merged = existing ? existing.items.slice(0) : [];
              if (args) {
                // Assume an offset of 0 if args.offset omitted.
                const { offset = 0 } = args;
                for (let i = 0; i < incomingItems.length; ++i) {
                  merged[offset + i] = incomingItems[i];
                }
              } else {
                // It's unusual (probably a mistake) for a paginated field not
                // to receive any arguments, so you might prefer to throw an
                // exception here, instead of recovering by appending incoming
                // onto the existing array.
                // eslint-disable-next-line prefer-spread
                merged.push.apply(merged, incomingItems);
              }
              return { ...incoming, items: merged };
            },
          },
        },
      },
    },
  }),
});

type GraphqlProviderProps = {
  children: React.ReactNode;
};

export function GraphqlProvider({ children }: GraphqlProviderProps) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
