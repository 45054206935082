import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Chrome } from "~/Chrome";
import { GraphqlProvider, PWAInstallProvider } from "~/providers";

import { GlobalStyles } from "./components/GlobalStyles";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <GlobalStyles />
    <PWAInstallProvider>
      <HelmetProvider>
        <GraphqlProvider>
          <BrowserRouter>
            <Chrome />
          </BrowserRouter>
        </GraphqlProvider>
      </HelmetProvider>
    </PWAInstallProvider>
  </StrictMode>
);
