import { animated, config, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import cx from "classnames";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";

import { css } from "@emotion/css";
import { useClickAnyWhere, useOnClickOutside } from "usehooks-ts";
import { closeDrawer } from "~/stores";
import * as styles from "./Drawer.module.css";

let DebugLine: any;
if (import.meta.env.DEV) {
  const val = (v: any) => "var(--v, " + v + "px)";
  // eslint-disable-next-line react/display-name
  DebugLine = ({
    axis,
    value,
    color,
    label,
    className = "",
    divRef,
  }: {
    axis: "x" | "y";
    value: number;
    color: string;
    label: string;
    className?: string;
    divRef?: React.LegacyRef<HTMLDivElement>;
  }) => (
    <div
      ref={divRef}
      className={className}
      style={{
        position: "absolute",
        transform: `translate3d(${axis === "x" ? val(value) : 0}, ${
          axis === "y" ? val(value) : 0
        }, 0px)`,
        width: axis === "x" ? 1 : "100%",
        height: axis === "y" ? 1 : "100%",
        zIndex: 9999,
        fontSize: "11px",
        background: color,
        color: color,
        textTransform: "uppercase",
        fontWeight: "bold",
      }}
    >
      {label}
    </div>
  );
}

type DrawerProps = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  onWillClose?: () => void;
  onWillOpen?: () => void;
  onClosed?: () => void;
  onOpened?: () => void;
  side?: "top" | "right" | "bottom" | "left";
  drawerSize?: number;
  alwaysVisibleSize?: number;
  debug?: boolean;
  zIndex?: number;
  label: string;
  unfocusElRef?: React.RefObject<HTMLElement>;
  closeWhenClickingOutside?: boolean;
};

const Drawer_ = ({
  isOpen,
  onWillClose = () => {},
  onWillOpen = () => {},
  onOpened = () => {},
  children,
  side = "right",
  drawerSize = 240,
  alwaysVisibleSize = 0,
  className = "",
  debug = false,
  zIndex,
  label,
  unfocusElRef,
  closeWhenClickingOutside = true,
  onClosed = () => {},
}: DrawerProps) => {
  const direction =
    side === "top" || side === "bottom" ? "vertical" : "horizontal";
  const isHorizontal = direction === "horizontal";
  const isVertical = direction === "vertical";

  const handleClosed = useCallback(() => {
    closeDrawer();
    onClosed();
  }, [onClosed]);

  const drawerClasses = cx({
    [className]: className,
    [styles.drawer]: true,
    [styles.drawerIsClosed]: !isOpen,
    [styles.drawerVertical]: isVertical,
    [styles.drawerHorizontal]: isHorizontal,
    [styles.drawerLeft]: side === "left",
    [styles.drawerRight]: side === "right",
    [styles.drawerTop]: side === "top",
    [styles.drawerBottom]: side === "bottom",
  });
  const drawerRef = useRef<HTMLDivElement>(null);

  // Basically when side is bottom or right the opened state uses a negative
  // transform, otherwise the same with a positive transform.
  const sideFactor = side === "bottom" || side === "right" ? -1 : 1;
  const normalizeNumber = useCallback(
    (v: number) => v * sideFactor,
    [sideFactor]
  );
  // compare numbers with < and > depending on their side
  const checkIfValueBeyond = useCallback(
    (value: number, valueToCompareWith: number) =>
      sideFactor === -1
        ? value < valueToCompareWith
        : value > valueToCompareWith,
    [sideFactor]
  );
  const openOffset = normalizeNumber(drawerSize);
  const closedOffset = normalizeNumber(alwaysVisibleSize);
  // touch values
  const cancelMovementThreshold = drawerSize * 0.5;
  const closeMovementThreshold = drawerSize * 0.5;
  const closeVelocityThreshold = 0.5;
  const closeDirectionThreshold = 0.1;
  const closeThreshold =
    normalizeNumber(drawerSize) - normalizeNumber(closeMovementThreshold);
  const cancelThreshold =
    normalizeNumber(drawerSize) + normalizeNumber(cancelMovementThreshold);

  const [{ x, y }, api] = useSpring(
    () => ({
      x: !isHorizontal ? 0 : isOpen ? openOffset : closedOffset,
      y: !isVertical ? 0 : isOpen ? openOffset : closedOffset,
      config: {
        clamp: true,
      },
    }),
    [isHorizontal, isVertical]
  );

  // The two imperative functions. isTriggeredFromProp prevents the onRest
  // callbacks from firing because this callback is used to set the isOpen
  // prop that is passed to this component.
  const open = useCallback(
    (canceled = false, isTriggeredFromProp = false) => {
      drawerRef.current?.setAttribute("aria-expanded", "true");
      window.document.documentElement.style.overscrollBehaviorY = "contain";
      api.start({
        x: !isHorizontal ? 0 : openOffset,
        y: !isVertical ? 0 : openOffset,
        immediate: false,
        config: canceled ? config.wobbly : config.stiff,
        onStart: onWillOpen,
        onRest: !isTriggeredFromProp ? onOpened : undefined,
      });
    },
    [api, isHorizontal, openOffset, isVertical, onWillOpen, onOpened]
  );

  const close = useCallback(
    (velocity = 2, isTriggeredFromProp = false) => {
      drawerRef.current?.setAttribute("aria-expanded", "false");
      window.document.documentElement.style.overscrollBehaviorY = "";
      api.start({
        x: !isHorizontal ? 0 : closedOffset,
        y: !isVertical ? 0 : closedOffset,
        immediate: false,
        config: { ...config.stiff, velocity },
        onStart: onWillClose,
        onRest: !isTriggeredFromProp ? handleClosed : undefined,
      });
    },
    [api, isHorizontal, closedOffset, isVertical, onWillClose, handleClosed]
  );

  useEffect(() => {
    if (isOpen) {
      open(false, true);
    } else {
      close(undefined, true);
    }
  }, [isOpen, open, close]);

  // When clicking outside of the drawer, call the onClosed callback, unless
  // there is an unfocusElRef. If that is defined, instead only close if this
  // element is clicked. (used to only close the sidebar drawer, when the
  // content area is clicked, not the header.)
  const handleOutsideClick = useCallback(() => {
    if (!unfocusElRef && closeWhenClickingOutside) {
      if (isOpen) {
        handleClosed();
      }
    }
  }, [isOpen, unfocusElRef, closeWhenClickingOutside, handleClosed]);
  useOnClickOutside(drawerRef, handleOutsideClick);

  useClickAnyWhere((e) => {
    if (unfocusElRef?.current) {
      if (
        e.target &&
        e.target instanceof Node &&
        unfocusElRef.current.contains(e.target)
      ) {
        handleClosed();
      }
    }
  });

  const mRef = useRef<HTMLDivElement>(null);
  const bindDrawerDrag = useDrag(
    ({
      last,
      velocity: [vx, vy],
      direction: [dx, dy],
      movement: [mx, my],
      cancel,
      canceled,
    }) => {
      const v = isHorizontal ? vx : vy;
      const d = isHorizontal ? dx : dy;
      const m = isHorizontal ? mx : my;
      const curr = m + (isOpen ? openOffset : closedOffset);

      if (mRef.current) {
        mRef.current?.style.setProperty(
          "--v",
          (Math.floor(curr) as unknown as string) + "px"
        );
      }

      if (checkIfValueBeyond(curr, cancelThreshold)) {
        cancel();
      }

      if (last) {
        if (
          checkIfValueBeyond(closeThreshold, curr) ||
          (v > closeVelocityThreshold && d > closeDirectionThreshold)
        ) {
          return close(v);
        } else {
          return open(canceled);
        }
      } else {
        api.start({
          x: isHorizontal ? curr : 0,
          y: isVertical ? curr : 0,
          immediate: true,
        });
      }
    },
    {
      rubberband: false,
      filterTaps: true,
      delay: 0,
    }
  );

  const drawerStyle = useMemo(
    () => ({
      ...(isVertical
        ? {
            height: drawerSize,
            y,
          }
        : {
            width: drawerSize,
            x,
          }),
      zIndex: zIndex,
      transformOrigin: sideFactor === -1 ? "top right" : "bottom left",
    }),
    [drawerSize, x, y, isVertical, sideFactor, zIndex]
  );

  return (
    <>
      {import.meta.env.DEV && debug && (
        <>
          <DebugLine
            axis={isHorizontal ? "x" : "y"}
            value={openOffset}
            label="openOffset"
            color="blue"
            className={drawerClasses}
          />
          <DebugLine
            axis={isHorizontal ? "x" : "y"}
            value={closedOffset}
            label="closedOffset"
            color="blue"
            className={drawerClasses}
          />
          <DebugLine
            axis={isHorizontal ? "x" : "y"}
            value={closeThreshold}
            label="closeThreshold"
            color="violet"
            className={drawerClasses}
          />
          <DebugLine
            axis={isHorizontal ? "x" : "y"}
            value={cancelThreshold}
            label="cancelThreshold"
            color="green"
            className={drawerClasses}
          />
        </>
      )}
      <animated.div
        aria-label={label}
        className={drawerClasses}
        ref={drawerRef}
        style={drawerStyle}
        {...bindDrawerDrag()}
      >
        <div
          className={css`
            user-select: none;
          `}
        >
          {children}
        </div>
      </animated.div>
    </>
  );
};

export const Drawer = memo(Drawer_);
