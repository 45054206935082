import { useStore } from "@nanostores/react";
import { action, map } from "nanostores";

export type UiState = {
  contentIsScrollable: boolean;
  contentHasOverlay: boolean;
  appHasOverlay: boolean;
  drawerState: DrawerState;
};
export type DrawerState = keyof typeof drawerState;

export const drawerState = {
  Closed: "Closed",
  Menu: "Menu",
  Filters: "Filters",
  Options: "Options",
  Search: "Search",
} as const;

const initialState: UiState = {
  contentIsScrollable: true,
  contentHasOverlay: false,
  appHasOverlay: false,
  drawerState: drawerState.Closed,
};

export const $ui = map(initialState);

export const useUiState = () => useStore($ui);

export const toggleDrawerState = action(
  $ui,
  "setDrawerState",
  ($ui, drawerState: DrawerState) => {
    if ($ui.get().drawerState === drawerState) {
      return $ui.setKey("drawerState", "Closed");
    }
    $ui.setKey("drawerState", drawerState);
  }
);

export const toggleMenuDrawer = () => toggleDrawerState("Menu");
export const toggleSearchDrawer = () => toggleDrawerState("Search");
export const toggleOptionsDrawer = () => toggleDrawerState("Options");
export const toggleFiltersDrawer = () => toggleDrawerState("Filters");
export const closeDrawer = () => $ui.setKey("drawerState", "Closed");
