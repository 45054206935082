const checkLocalStorageSupport = () => {
  if (!window.localStorage) {
    console.warn(
      "Browser does not support localStorage! Persistence is disabled!"
    );
    return false;
  }
  return true;
};
const assertKeyExists = (key: unknown) => {
  if (!key) {
    throw new Error("Missing key!");
  }
};

const keyPrefix = (key: string) => `siro-app.${key}`;

export const saveData = <T>(key: string, data: T): void => {
  assertKeyExists(key);

  if (checkLocalStorageSupport()) {
    localStorage.setItem(keyPrefix(key), JSON.stringify(data));
  }
};

export const getData = <T>(key: string): T | undefined => {
  assertKeyExists(key);

  if (checkLocalStorageSupport()) {
    const item = localStorage.getItem(keyPrefix(key));
    if (item) {
      return JSON.parse(item);
    }
  }
};

export const removeData = (key: string): void => {
  assertKeyExists(key);
  if (checkLocalStorageSupport()) {
    localStorage.removeItem(keyPrefix(key));
  }
};
