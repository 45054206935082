import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Ellipsis } from "ftellipsis";

export const useVerticalLineClamp = <
  T extends HTMLElement = HTMLDivElement
>() => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const ellipsisInstance = useRef<Ellipsis | null>(null);
  const clamped = useRef(false);
  const [shouldClamp, setShouldClamp] = useState(false);
  const update = useCallback(() => {
    if (!ref) {
      return;
    }
    if (shouldClamp) {
      if (!ellipsisInstance.current) {
        ellipsisInstance.current = new Ellipsis(ref);
      }
      ellipsisInstance.current.calc();
      ellipsisInstance.current.set();
      clamped.current = true;
    } else {
      if (ellipsisInstance.current && clamped.current) {
        ellipsisInstance.current.unset();
        clamped.current = false;
      }
    }
  }, [ref, shouldClamp]);

  useEffect(
    () => () => {
      if (ellipsisInstance.current) {
        ellipsisInstance.current.destroy();
        ellipsisInstance.current = null;
        clamped.current = false;
      }
    },
    []
  );

  const handleClamp = useCallback(() => {
    setShouldClamp(true);
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShouldClamp, update, ref]);

  const handleUnclamp = useCallback(
    () => setShouldClamp(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShouldClamp, ref]
  );

  useLayoutEffect(() => {
    if (shouldClamp) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth, shouldClamp]);

  return {
    setRef,
    clamp: handleClamp,
    unclamp: handleUnclamp,
    update: update,
  };
};
