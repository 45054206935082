import { css } from "@emotion/css";
import { Location } from "history";
import { useEffect, useRef } from "react";
import { matchPath, useLocation, useMatch, useRoutes } from "react-router-dom";
import { Analytics } from "~/components/Analytics";
import { BottomBar } from "~/components/BottomBar";
import { BreadcrumbContainer } from "~/components/Breadcrumb";
import { ContentArea } from "~/components/ContentArea";
import { PreviewSlideUp } from "~/components/PreviewSlideUp";
import { Sidebar } from "~/components/Sidebar";
import { TopBar } from "~/components/TopBar";
import { routeConfig } from "~/routes/routeConfig";
import { OverlayTooltip } from "./components/OverlayTooltip";
import { PWAInstallHints } from "./components/PWAInstallHints";
import { usePWAInstallContext } from "./providers";
import { useUiState } from "./stores";

export const Chrome = () => {
  const routeElement = useRoutes(routeConfig);

  const isFrontPage = useMatch("/");
  const { contentHasOverlay, contentIsScrollable } = useUiState();
  const { hasVisibleInstallToolTip, handleCloseInstallTooltip, platform } =
    usePWAInstallContext();
  const contentAreaRef = useRef(null);

  // Escape client-side routing when attempting to navigate to /backend/*
  const { pathname: path }: Location = useLocation();
  useEffect(() => {
    const isBackend = matchPath({ path: "/backend/*", end: false }, path);
    if (isBackend) {
      window.open(location.href, "_self");
    }
  }, [path]);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <Analytics />
      <OverlayTooltip
        onClose={handleCloseInstallTooltip}
        isOpen={hasVisibleInstallToolTip}
      >
        <PWAInstallHints platform={platform} />
      </OverlayTooltip>
      <ContentArea
        isScrollable={!hasVisibleInstallToolTip}
        isOverlayed={hasVisibleInstallToolTip}
      >
        <TopBar />
        <div
          className={css`
            display: flex;
            height: 100%;
            overflow: hidden;
            position: relative;
          `}
        >
          <Sidebar blurRef={contentAreaRef} />
          <ContentArea
            ref={contentAreaRef}
            isOverlayed={contentHasOverlay}
            isScrollable={contentIsScrollable}
          >
            {!isFrontPage && <BreadcrumbContainer routes={routeConfig} />}
            <div
              className={css`
                flex: 1;
                display: flex;
                flex-direction: column;
              `}
            >
              {routeElement}
            </div>
          </ContentArea>

          <PreviewSlideUp />
        </div>
        <BottomBar routes={routeConfig} />
      </ContentArea>
    </div>
  );
};
