import get from "lodash/get";
import { memo, useCallback, useMemo } from "react";
import { ChevronLeft, ChevronRight, Filter, Share } from "react-feather";
import { matchRoutes, useLocation } from "react-router-dom";
import { iconStrokeWidth } from "~/constants";
import { RouteDefinition } from "~/routes/routeConfig";

import { useStore } from "@nanostores/react";
import { $settings, toggleFiltersDrawer, useUiState } from "~/stores";
import * as styles from "./BottomBar.module.css";
import { BottomBarButton } from "./BottomBarButton";

type BottomBarProps = {
  routes: RouteDefinition[];
};

const defaultOptions = {
  filterable: false,
  shareable: false,
  favoritable: false,
};

const BottomBar_ = ({ routes }: BottomBarProps) => {
  const { drawerState } = useUiState();
  const location = useLocation();
  const { topics, periods } = useStore($settings);
  const hasFiltersActive =
    (topics && topics.length > 0) || (periods && periods.length > 0);
  const matches = matchRoutes(routes, location);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleClickBack = useCallback(() => window.history.back(), []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleClickForward = useCallback(() => {}, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickFavorite = useCallback(() => {}, []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleClickShare = useCallback(() => {
    const canonical = document.querySelector("link[rel=canonical]");
    const url =
      canonical && (canonical as any).href
        ? (canonical as any).href
        : document.location.href;
    const title = document.title;
    if (navigator.share) {
      navigator.share({
        title,
        url,
        text: title,
      });
    } else {
      console.warn("Sharing is not possible!");
    }
  }, []);

  const routeOptions = useMemo<typeof defaultOptions | undefined>(
    () => get(matches, "0.route.options", defaultOptions),
    [matches]
  );
  const canShare = useMemo<boolean>(() => "share" in navigator, []);

  return (
    <nav className={styles.bottomBar}>
      <BottomBarButton
        enabled={true}
        title="Zurück"
        isActive={false}
        onClick={handleClickBack}
      >
        <ChevronLeft strokeWidth={iconStrokeWidth} />
      </BottomBarButton>
      <BottomBarButton
        enabled={false}
        title="Vorwärts"
        isActive={false}
        onClick={handleClickForward}
      >
        <ChevronRight strokeWidth={iconStrokeWidth} />
      </BottomBarButton>
      <BottomBarButton
        enabled={!!routeOptions?.shareable && canShare}
        title="Inhalt teilen"
        isActive={false}
        onClick={handleClickShare}
      >
        <Share strokeWidth={iconStrokeWidth} />
      </BottomBarButton>
      <BottomBarButton
        enabled={!!routeOptions?.filterable}
        isActive={drawerState === "Filters"}
        title="Ansicht filtern"
        onClick={toggleFiltersDrawer}
        hasHint={hasFiltersActive}
      >
        <Filter strokeWidth={iconStrokeWidth} />
      </BottomBarButton>
    </nav>
  );
};

export const BottomBar = memo(BottomBar_);
